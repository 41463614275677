import React from 'react';
import { Col, Row } from 'components/Core/Grid';
import { Parallax } from 'rc-scroll-anim';
import { colorTypeHelper } from 'utils/colorUtility';
import WebsiteImage from 'components/WebsiteImage';
import ContentfulRichText from 'components/RichText/ContentfulRichText';
import HeroContextualCTA from 'components/Contentful/ContentfulDynamicHero/HeroContextualCTA';
import { getEntry } from 'components/Contentful/ContentfulLayout/utils/entriesHelpers';

import { HeroContainer } from './styles';

const ParallaxHero = ({ entries }) => {
  const { backgroundImage, backgroundColor, customBackgroundMobile, customBackgroundDesktop } =
    getEntry('section', entries)?.styles;

  const bgColor = colorTypeHelper[backgroundColor];
  const bgImage = backgroundImage?.image?.file?.url;

  return (
    <HeroContainer
      $bgColor={bgColor}
      $backgroundImage={bgImage}
      $customBgDesktop={customBackgroundDesktop}
      $customBgMobile={customBackgroundMobile}
    >
      <Row gutter={80} justify="space-between">
        <Col xs={24} md={12} className="copy">
          <ContentfulRichText richText={getEntry('title', entries)} />
          <div className="subtitle">
            <ContentfulRichText richText={getEntry('subtitle', entries)} />
          </div>
          <HeroContextualCTA
            ctaPosition="emailcapture-hero"
            content={getEntry('cta', entries)}
            contentLeftAligned={false}
            fullWidth
          />
        </Col>
        <Col xs={24} md={12} className="featured-image--container max-md">
          <Parallax
            animation={{ translateX: -100, playScale: [0, 2] }}
            style={{ transform: 'translateX(-40px)' }}
          >
            <WebsiteImage
              loading="eager"
              className="featured-content-image"
              {...getEntry('image', entries)}
              style={{ marginTop: '0px' }}
            />
          </Parallax>
        </Col>
        <Col xs={24} md={12} className="featured-image--container min-md">
          <Parallax
            animation={{ translateY: -570, playScale: [0.7, 3] }}
            style={{ transform: 'translateY(-250px)' }}
            className="marketing-pro-value--inner"
          >
            <WebsiteImage
              loading="eager"
              className="featured-content-image"
              {...getEntry('image', entries)}
              style={{ marginTop: '0px' }}
            />
          </Parallax>
        </Col>
      </Row>
    </HeroContainer>
  );
};

export default ParallaxHero;
