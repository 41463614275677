import styled from 'styled-components';
import { SectionLayout } from 'components/Sections/SectionLayout';

const HeroContainer = styled(SectionLayout)`
  height: 850px;
  background: ${({ $bgColor, $customBgMobile, $customBgDesktop }) =>
    $customBgDesktop || $customBgMobile || $bgColor || `var(${$bgColor || '--skyline-3'})`};
  background-image: ${({ $backgroundImage }) => `url(${$backgroundImage})`};
  background-size: 100% 900px;

  .section-layout--inner-container {
    height: 100%;
  }

  .subtitle {
    max-width: 500px;
  }

  h1 {
    margin-top: 160px;
  }

  .featured-image--container {
    position: absolute;
    top: 20px;
    right: 0;
  }

  .copy {
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    height: max-content;

    a {
      background-color: var(--titan-blue-3);

      &:hover {
        cursor: pointer;
      }
    }
  }

  @media (min-width: 1296px) {
    .copy {
      margin-left: 15px;
    }
  }

  @media (max-width: 1199px) {
    .section-layout--inner-container {
      padding-bottom: 305px;
      padding-top: 170px;
    }
  }

  @media (max-width: 991px) {
    height: 700px;

    .featured-image--container {
      top: inherit;
    }
  }

  @media (max-width: 767px) {
    height: auto;

    h1 {
      margin: 0 0 20px 0;
    }

    .copy {
      position: relative;
      top: inherit;
      right: inherit;
      text-align: left;

      .hero-contextual-cta--origin {
        margin-top: 10px;
      }

      a {
        margin-top: 0 !important;
      }
    }

    .featured-image--container {
      position: relative;
      top: 60px;
      right: inherit;
      width: 100vw;
      left: 0;

      .featured-content-image {
        max-width: none;
        width: 105vw;
      }
    }

    .section-layout--inner-container {
      padding-top: 140px;
      padding-bottom: 140px;
    }

    .subtitle {
      max-width: 400px;
    }
  }

  @media (max-width: 575px) {
    background: ${({ $bgColor, $customBgMobile }) =>
      $customBgMobile || $bgColor || `var(${$bgColor || '--skyline-3'})`};

    .featured-image--container {
      left: -15px;

      .featured-content-image {
        max-width: none;
        width: 120vw;
      }
    }
  }
`;

export { HeroContainer };
